@import '@sass-mixins';

.base {
  border-top: 1px solid transparent;
  // margin-top: 82px;

  // background-color: rgba(15, 25, 62, 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);

  :global(.dark) & {
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  }

  &:before {
    content: unset !important;
  }

  @include breakpoint('sm-max') {
    // margin-top: 22px;
    // background: linear-gradient(100.12deg,
    //     var(--theme-box-bg-default) 0.86%,
    //     rgba(18, 25, 47, 0) 114.54%);
    box-sizing: border-box;
    border-top: 1px solid var(--theme-box-border);

    &:before {
      content: unset !important;
    }
  }
}

.mobile {
  .cards {
    @include breakpoint('sm-max') {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;
      grid-column: 2;
      grid-row: 1;
    }
  }
}


.main {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 20px 0;
  justify-content: center;

  @include breakpoint('lg-max') {}

  @include breakpoint('md-max') {}

  @include breakpoint('sm-max') {}
}