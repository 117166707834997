@import '@sass-mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .childrencontainer > *:first-child {
    min-height: 100vh;
  }
}
