.relative {
  position: relative;
}

.zZero {
  z-index: 0;
}

.wFull {
  width: 100%;
}

.mb5 {
  //margin-bottom: 1rem; /* Adjust as needed */
  margin-top: 1rem;
}

.input {
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  appearance: none;
  outline: none;
  box-shadow: none;
  font-size: 15px;
}
.textarea{
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  appearance: none;
  outline: none;
  box-shadow: none;
}

.input:focus {
  border-color: black;
}

.inputBorderGray {
  border-color: #e5e7eb; /* Adjust as needed */
}

.label {
  position: absolute;
  font-size: 15px !important;
  transition-duration: 0.3s;
  top: 0.75rem;
  z-index: -1;
  transform-origin: 0%;
  color: #6b7280; /* Adjust as needed */
}

.labelFocus {
  --tw-translate-x: 0;
  --tw-translate-y: -1.5rem;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) ;
  color: black;
}

.hidden {
  display: none;
}

.textSm {
  font-size: 0.875rem; /* Adjust as needed */
}

.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label {
  --tw-translate-y: -1.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateY(var(--tw-translate-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
