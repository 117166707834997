@import '@sass-mixins';

.formContainer {
  padding-top: 20px;

  .title {
    font-size: 15px !important;
    font-weight: 700 !important;
  }

  .titleRequire {
    font-size: 15px !important;
    font-weight: 700 !important;

    &::after {
      content: ' *';
      color: #a94442;
    }
  }

  .titleSecondary {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .titleSecondaryRequire {
    font-size: 15px !important;
    font-weight: 400 !important;

    &::after {
      content: ' *';
      color: #a94442;
    }
  }

  .text {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  hr {
    margin: 50px 0 30px 0;
  }
}

.buttonLocation {
  font-size: 16px !important;
  color: #1B1B1B !important;
  background: #FDB913 !important;
  line-height: 45px !important;
  min-width: 100px !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  border: none !important;
  padding: 0px !important;
  width: 100%;
  height: 50px;
}

.groupRadio {
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  display: inline;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;

  button {
    color: #1B1B1B;
    background: #FDB913;
    height: 40px;
    min-width: 100px;
  }
}

.marginTopDefault {
  margin-top: 10px;
}

.contentContainer {
  // margin-left: 20px;
}

.paddingGroup {
  padding-top: 10px;
}